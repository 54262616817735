import {CollectionOrgPeopleDeleted} from '@bitkey-service/v2_core-types/lib/store/organizations/people-deleted/v2_storeTypesOrgPeopleDeleted';
import {query, where} from 'firebase/firestore';

import {Firestore} from '../firebase-firestore';

const ref = (organizationId: string) =>
  Firestore.getInstance().getCollectionRef(CollectionOrgPeopleDeleted(organizationId));

export class FirestoreOrgPeopleDeleted {
  public static ref = (organizationId: string) => ref(organizationId);
  public static getAll = (organizationId: string) => Firestore.getInstance().getByQuery(ref(organizationId));
  public static getById = (organizationId: string, peopleId: string) =>
    Firestore.getInstance().getById(ref(organizationId), peopleId);
  public static getByIds = (organizationId: string, peopleIds: string[]) =>
    Firestore.getInstance().getByIds(ref(organizationId), peopleIds);
  public static getByPersonaId = async (organizationId: string, personaId: string) => {
    const deletedPerson = await Firestore.getInstance().getByQuery(
      query(ref(organizationId), where('personaId', '==', personaId))
    )[0];
    return deletedPerson;
  };
  public static getByPersonaIds = async (organizationId: string, personaIds: string[]) => {
    const count = Math.ceil(personaIds.length / 10);
    const idGroups = Array.from({length: count}).map((_, i) => personaIds.slice(i * 10, (i + 1) * 10));
    const promises = idGroups.map(ids =>
      Firestore.getInstance().getByQuery(query(ref(organizationId), where('personaId', 'in', ids)))
    );
    const results = await Promise.all(promises);
    return results.flat();
  };
}
