import {AccessControlVersionSettings} from '@bitkey-service/v2_core-types/lib/store/organizations/v2_storeTypesOrganization';
import {
  AuthorityLevel,
  FeatureAuthority,
} from '@bitkey-service/workhub-types/lib/firestore/organizations/authority-pattern/firestoreTypesOrganizationsAuthorityPattern';
import dayjs from 'dayjs';

import {FsAuthorityPattern} from '@/firestore/types/fsAuthorityPatternType';

import {ActivationFeatureGroupMap, ActivationGroup, Feature, FeatureGroup} from './featureDefinitions';
import {FeatureControlHelper} from './helpers/featureControlHelper';
import {workhubAppDeviceSettingFeatures} from '../../services/authority/authorityScreenService';
import {routes} from '../../wscreens/routing/routing';

export class FeatureControl {
  // これがないとsuperUserと権限未設定の一般ユーザーの区別がつかないため
  private readonly isSuperUser: boolean;

  private constructor(isSuperUser: boolean) {
    this.isSuperUser = isSuperUser;
  }

  public static createInstance = (isSuperUser: boolean) => new FeatureControl(isSuperUser);

  public convertAuthorityLevelForThirdPlaceCustomer = (featureAuthority: FeatureAuthority) => {
    const authority = {...featureAuthority};
    // 会員に付与できる権限
    const allowFeatures: Feature[] = [
      Feature.Mypage,
      Feature.Announcement,
      Feature.ReservationCalendarMySchedule,
      Feature.ReservationCalendar,
      Feature.ReservationThingCalendar,
      Feature.V2LockerReservationCalendar,
      Feature.TrafficConditions,
      Feature.V2LocationSearch,
      Feature.AboutBitkey,
      Feature.V2WorkhubAppOrganization,
    ];
    // それ以外の権限はNoneに上書き
    const targetFeatures = Object.values(Feature).filter(feature => !allowFeatures.includes(feature));
    for (const targetFeature of targetFeatures) {
      authority[targetFeature] = AuthorityLevel.None;
    }
    return authority;
  };

  // view用に権限を変換する
  // マイグレーション後に削除予定
  // ref: https://bit-key.atlassian.net/browse/WWA-14858
  // ref: https://bit-key.atlassian.net/browse/WWA-14859
  public convertAuthorityLevel = (
    featureAuthority: FsAuthorityPattern['authority']
  ): FsAuthorityPattern['authority'] => {
    return {
      ...featureAuthority,
      // v2Signageを新しく用意したkeyに変換する
      signageEmployeeAttendance: featureAuthority.signageEmployeeAttendance ?? featureAuthority.v2Signage,
      signageCheckInInformation: featureAuthority.signageCheckInInformation ?? featureAuthority.v2Signage,
      signageTrafficCondition: featureAuthority.signageTrafficCondition ?? featureAuthority.v2Signage,
    };
  };

  public getFilteredRouting = ({
    activationGroups,
    featureAuthority,
    isBmMigrateActivate,
    tempAccessControlVersionSettings,
  }: {
    activationGroups: ActivationGroup[];
    featureAuthority: FeatureAuthority;
    isBmMigrateActivate?: boolean;
    tempAccessControlVersionSettings?: AccessControlVersionSettings;
  }) => {
    const featureControlHelper = FeatureControlHelper.createInstance(this.isSuperUser);
    const featureGroups = featureControlHelper.getActivatedFeatureGroups(activationGroups);
    const features = featureControlHelper.getAuthorizedFeatures(featureAuthority);
    if (isBmMigrateActivate) {
      features.push(Feature.BmMigrateAssistant);
    }
    if (import.meta.env.VITE_MODE === 'dev') {
      return routes;
    }
    return routes
      .map(r => {
        return {
          ...r,
          children:
            r.type === 'withChildren'
              ? featureControlHelper.filteredChildrenFeature(
                  r.children,
                  featureGroups as FeatureGroup[],
                  features,
                  tempAccessControlVersionSettings
                )
              : [],
        };
      })
      .filter(r => {
        if (r.type === 'withChildren') {
          return r.children.length > 0;
        } else {
          return featureGroups.includes(r.featureGroup) && r.feature && features.includes(r.feature);
        }
      });
  };

  /**
   * @param activations UserStateと同様にTimestampはEpochMillisになっているが、activationGroupはV2StoreTypesOrgActivationsと一致していないのでインターフェースとして受ける形にする
   * @returns
   */
  public getActivatedAuthorityPattern = (
    activations: {from: number; to: number; activationGroup: ActivationGroup}[]
  ): Feature[] => {
    const now = dayjs();
    const activationGroups = activations
      .filter(act => dayjs(act.from).isBefore(now) && dayjs(act.to).isAfter(now))
      .map(act => act.activationGroup);
    const featureGroups: FeatureGroup[] = [];
    for (const featureGroup of Object.keys(ActivationFeatureGroupMap)) {
      const actGroups = ActivationFeatureGroupMap[featureGroup];
      if (activationGroups.some(g => actGroups.includes(g)) && !featureGroups.includes(featureGroup as FeatureGroup)) {
        featureGroups.push(featureGroup as FeatureGroup);
      }
    }
    const features: any[] = [];
    for (const rt of routes) {
      if (rt.type === 'withChildren') {
        const children = rt.children;
        for (const child of children) {
          if (featureGroups.includes(child.featureGroup) && child.feature && !features.includes(child.feature)) {
            features.push(child.feature);
          }
        }
      } else {
        if (featureGroups.includes(rt.featureGroup) && rt.feature && !features.includes(rt.feature)) {
          features.push(rt.feature);
        }
      }
    }

    //メニュー項目にはない特殊なもの
    if (featureGroups.includes(FeatureGroup.V2BitlockApp)) {
      features.push(Feature.V2BitlockApp);
    }

    if (featureGroups.includes(FeatureGroup.V2ReceptionApp)) {
      features.push(Feature.V2ReceptionApp);
    }

    if (featureGroups.includes(FeatureGroup.SecuritySetting)) {
      features.push(Feature.SecuritySetting);
    }

    if (featureGroups.includes(FeatureGroup.AzbilCloudOperation)) {
      features.push(Feature.AzbilCloudOperation);
    }

    if (featureGroups.includes(FeatureGroup.TabletOperation)) {
      features.push(Feature.TabletOperation);
    }

    if (featureGroups.includes(FeatureGroup.ThirdPlaceBceSetting)) {
      features.push(Feature.ThirdPlaceBceSetting);
    }

    if (featureGroups.includes(FeatureGroup.SpaceAuthoritySetting)) {
      features.push(Feature.SpaceAuthoritySetting);
    }

    if (featureGroups.includes(FeatureGroup.DeleteFaceInformationForGuest)) {
      features.push(Feature.DeleteFaceInformationForGuest);
    }
    if (featureGroups.includes(FeatureGroup.ReservationCalendarAddPeopleCsv)) {
      features.push(Feature.ReservationCalendarAddPeopleCsv);
    }

    // workhub appの制御
    features.push(Feature.V2WorkhubAppOrganization);
    features.push(Feature.WorkhubAppPersonalizedNfcCard);
    //Appでのデバイス操作系の権限制御
    features.push(Feature.WorkhubAppDeviceSetting);
    for (const workhubAppDeviceFeature of workhubAppDeviceSettingFeatures) {
      features.push(workhubAppDeviceFeature);
    }

    features.push(Feature.LocationInformationCSVOutput);

    return Object.values(Feature).filter(f => features.includes(f));
  };
}
