import {AxiosRequestConfig} from 'axios';

import {FirebaseFunctions} from '@/common/firebase/functions/firebase-functions';

import {ApiWorkhubCore} from '../apiWorkhubCore';

const basePath = () => {
  return `system`;
};
export class V2ApiWorkhubCoreSystem {
  private static post = <Response, Request>(path: string, data: Request, config?: AxiosRequestConfig) =>
    ApiWorkhubCore.post<Response, Request>(FirebaseFunctions.makePath(basePath(), path), data, config);
  private static get = <Response>(path: string) =>
    ApiWorkhubCore.get<Response>(FirebaseFunctions.makePath(basePath(), path));
  private static patch = <Response, Request>(path: string, data: Request, config?: AxiosRequestConfig) =>
    ApiWorkhubCore.patch<Response, Request>(FirebaseFunctions.makePath(basePath(), path), data, config);
  private static delete = <Response>(path: string, config?: AxiosRequestConfig) =>
    ApiWorkhubCore.delete<Response>(FirebaseFunctions.makePath(basePath(), path), config);

  /**
   * AlgoliaのSettingsの反映
   */
  public static setAlgoliaSettings = () => V2ApiWorkhubCoreSystem.post<any, any>('/algolia/settings/reflects', {});
}
