import MuiTooltip, {TooltipProps as MuiTooltipProps} from '@mui/material/Tooltip';
import React from 'react';
import {withStyles} from 'tss-react/mui';

import {WHColor} from '@/common/styles/whColor';
import {WHFont} from '@/common/styles/whFont';
import {WHRadius} from '@/common/styles/whRadius';
import {WHShadow} from '@/common/styles/whShadow';
import {WHSpacing} from '@/common/styles/whSpacing';

const BaseTooltip = withStyles(MuiTooltip as React.ComponentType<MuiTooltipProps>, _theme => ({
  tooltip: {
    borderRadius: `${WHRadius.radiusM}`,
    boxShadow: `${WHShadow.none}`,
    backgroundColor: `${WHColor.surface.neutralDark}`,
    color: `${WHColor.text.neutralWhitePrimary}`,
    padding: `${WHSpacing.spacing4} ${WHSpacing.spacing8}`,
    whiteSpace: 'pre-line', // エスケープ文字を使って改行する時に必要 @see https://www.w3.org/TR/CSS21/text.html#white-space-prop
    ...WHFont.bodySmallStrong,
  },
}));

const Tooltip: React.FC<MuiTooltipProps> = ({children, ...others}) => {
  return (
    <BaseTooltip disableInteractive {...others}>
      {/** Tooltipとカスタムコンポーネントと互換性を持たせるため */}
      {/* @see https://mui.com/material-ui/react-tooltip/#custom-child-element */}
      {/* @see https://mui.com/material-ui/react-tooltip/#disabled-elements */}
      <span>{children}</span>
    </BaseTooltip>
  );
};
export default React.memo(Tooltip);
export type TooltipProps = MuiTooltipProps;
