import styled from '@emotion/styled';
import React, {useCallback, useState} from 'react';

import {WHColor} from '@/common/styles/whColor';
import AdvertiseBannerScreen from '@/wscreens/welcome/AdvertiseBannerScreen';

import LoginScreen from './LoginScreen';
import LoginWithExternalIdScreen from './LoginWithExternalIdScreen';
import ResetPasswordScreen from './ResetPasswordScreen';

import './WelcomeScreen.css';

const Root = styled.div`
  width: 100vw;
  height: 100vh;
  min-height: 680px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${WHColor.surface.brandPrimary};
`;

const LoginWrapper = styled.div`
  background-color: ${WHColor.surface.brandPrimary};
  flex: 0 0 50%;
  overflow-x: hidden;
`;
const LoginContent = styled.div`
  width: 200%;
  display: flex;
`;

const LoginContentItem = styled.div`
  flex: 0 0 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export enum TransitionTab {
  LOGIN_SCREEN = 'login-screen',
  RESET_PASSWORD_SCREEN = 'reset-password-screen',
  LOGIN_WITH_EXTERNAL_ID_SCREEN = 'login-with-external-id-screen',
}

const IMAGES = [
  {
    href: `https://www.workhub.site/interview/rizap?utm_source=${
      import.meta.env.VITE_ENVIRONMENT !== 'production' ? 'dev' : ''
    }_workhub_admin_web&utm_medium=${
      import.meta.env.VITE_ENVIRONMENT !== 'production' ? 'dev_' : ''
    }web_login_banner_rizap&utm_campaign=${
      import.meta.env.VITE_ENVIRONMENT !== 'production' ? 'dev' : ''
    }_proto&utm_id=${import.meta.env.VITE_ENVIRONMENT !== 'production' ? 'dev' : ''}_initial_test_2023_12_19`,
    alt: 'インタビュー事例 RIZAPグループ様 RIZAPグループ約20社をつなぐ「進化するオフィス」。組織力の強化に貢献するworkhubの拡張性',
    imgSrc: '/image/welcome/workhub_top_baner_rizap.png',
    sourceSrcList: [
      '/image/welcome/workhub_top_baner_rizap.png 1x',
      '/image/welcome/workhub_top_baner_rizap@2x.png 2x',
    ],
  },
  {
    href: `https://www.workhub.site/interview/rjoffice?utm_source=${
      import.meta.env.VITE_ENVIRONMENT !== 'production' ? 'dev' : ''
    }_workhub_admin_web&utm_medium=${
      import.meta.env.VITE_ENVIRONMENT !== 'production' ? 'dev' : ''
    }_web_login_banner_rj_office&utm_campaign=${
      import.meta.env.VITE_ENVIRONMENT !== 'production' ? 'dev' : ''
    }_proto&utm_id=${import.meta.env.VITE_ENVIRONMENT !== 'production' ? 'dev' : ''}_initial_test_2023_12_19`,
    alt: 'インタビュー事例 RJオフィス株式会社様 workhubが持つ「2つの柔軟性」、ホテルからシェアオフィスへ変遷するIsaI AkasakAを支える適応力と発展性',
    imgSrc: '/image/welcome/workhub_top_baner_rjoffice.png',
    sourceSrcList: [
      '/image/welcome/workhub_top_baner_rjoffice.png 1x',
      '/image/welcome/workhub_top_baner_rjoffice@2x.png 2x',
    ],
  },
];

interface P {
  isJumpResetPasswordScreen?: boolean;
}

const WelcomeScreen = React.memo<P>(function WelcomeScreen({isJumpResetPasswordScreen}) {
  const [transitionTab, setTransitionTab] = useState<TransitionTab>(
    isJumpResetPasswordScreen ? TransitionTab.RESET_PASSWORD_SCREEN : TransitionTab.LOGIN_SCREEN
  );
  const transitionResetPasswordScreen = useCallback(() => setTransitionTab(TransitionTab.RESET_PASSWORD_SCREEN), []);
  const transitionLoginWithExternalIDScreen = useCallback(
    () => setTransitionTab(TransitionTab.LOGIN_WITH_EXTERNAL_ID_SCREEN),
    []
  );
  const transitionLoginScreen = useCallback(() => setTransitionTab(TransitionTab.LOGIN_SCREEN), []);

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  return (
    <Root>
      {transitionTab === TransitionTab.LOGIN_SCREEN && (
        <>
          <LoginWrapper>
            <LoginContent>
              {transitionTab === TransitionTab.LOGIN_SCREEN && (
                <LoginContentItem>
                  <LoginScreen
                    transitionResetPasswordScreen={transitionResetPasswordScreen}
                    transitionLoginWithExternalIDScreen={transitionLoginWithExternalIDScreen}
                    email={email}
                    setEmail={setEmail}
                    password={password}
                    setPassword={setPassword}
                  />
                </LoginContentItem>
              )}
            </LoginContent>
          </LoginWrapper>
          <AdvertiseBannerScreen
            css={{position: 'relative', zIndex: 1, alignSelf: 'stretch', flex: '0 0 50%'}}
            images={IMAGES}
          />
        </>
      )}
      {transitionTab === TransitionTab.RESET_PASSWORD_SCREEN && (
        <ResetPasswordScreen transitionLoginScreen={transitionLoginScreen} email={email} setEmail={setEmail} />
      )}
      {transitionTab === TransitionTab.LOGIN_WITH_EXTERNAL_ID_SCREEN && (
        <LoginWithExternalIdScreen transitionLoginScreen={transitionLoginScreen} email={email} setEmail={setEmail} />
      )}
    </Root>
  );
});

export default WelcomeScreen;
