import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import MuiTextField from '@mui/material/TextField';
import React, {useCallback, useState} from 'react';
import {makeStyles, withStyles} from 'tss-react/mui';
import {z} from 'zod';

import {SsoIdProviders, SsoKeys, SsoOperations} from '@/common/constant/cookie';
import {addCookieValueByKey} from '@/common/storage/cookie';
import {WHColor, shadowColor} from '@/common/styles/whColor';
import {WHFontCss} from '@/common/styles/whFont';
import WButton from '@/components/button/WButton';
import WorkhubLogoIcon from '@/components/icon/WorkhubLogoIcon';
import {OidcSsoService} from '@/v2_service/oidc/sso/oidcSsoService';

import useDict from '../../common/hooks/useDict';
import {Locale} from '../../common/redux/state-types/localeStateType';
import WLoadingComponent from '../../components/figma/others/stepper/WLoadingComponent';

interface P {
  transitionLoginScreen: () => void;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
}

const LoginFormArea = styled.div`
  width: 385px;
  height: 680px;
  padding: 16px;
  box-sizing: border-box;
  box-shadow: 0 4px 10px ${shadowColor.shadowAlpha020};
  border-radius: 8px;
  background-color: ${WHColor.surface.neutralLow};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TitleArea = styled.div`
  height: 220px;
  width: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Heading = styled.h1`
  ${WHFontCss.labelMedium}
  color: ${WHColor.text.neutralPrimary};
  text-align: center;
  margin-top: 32px;
  white-space: pre-wrap;
`;

const InputArea = styled.div`
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
  margin-top: 20px;
`;

const ErrorMessageArea = styled.div`
  color: ${WHColor.text.semanticError};
  margin-top: 16px;
  text-align: center;
  white-space: pre-wrap;
`;

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ButtonArea = styled.div`
  margin-top: 50px;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  margin-top: 24px;
`;

const useStyles = makeStyles()(() => ({
  cancelButton: {
    borderRadius: 4,
    fontSize: '14px',
    backgroundColor: `${WHColor.surface.neutralLow}`,
    '&:hover': {
      backgroundColor: `${WHColor.surface.neutralLow}`,
    },
    color: `${WHColor.text.neutralPrimary}`,
  },
}));

const BaseTextFiled = withStyles(MuiTextField, {
  root: {
    height: '61px',
    border: '2px',
    background: `${WHColor.surface.neutralLow}`,
    marginBottom: '2px',
    fontSize: '14px',
    '& .MuiInput-underline:before': {
      borderBottom: `2px solid ${WHColor.border.onLightDefault}`,
      color: `${WHColor.text.neutralSecondary}`,
      fontSize: '14px',
    },
    '& .MuiInputLabel-root': {
      opacity: 0.8,
    },
  },
});

const dictDef = {
  errorMessage: {
    default: {
      default: 'メールアドレスまたは組織コードが違うか\n外部アカウントとの連携が完了していません',
      [Locale.en_US]: 'The email or organization code is incorrect\nor the external account is not linked',
    },
  },
  descriptionText: {
    default: {
      default: `ご登録されたメールアドレス\nまたは組織コードを入力してください`,
      [Locale.en_US]: 'Please enter your registered email address\nor organization code',
    },
  },
  loginWithExternalId: {
    default: {
      default: '外部IDでログイン',
      [Locale.en_US]: 'Login with external ID',
    },
  },
  cancel: {
    default: {
      default: 'キャンセル',
      [Locale.en_US]: 'Cancel.',
    },
  },
  emailOrOrgCode: {
    default: {
      default: 'メールアドレスまたは組織コード',
      [Locale.en_US]: 'Email or organization code',
    },
  },
};

const emailSchema = z.string().email();

const LoginWithExternalIdScreen = React.memo(function ResetPasswordScreen(props: P) {
  const dict = useDict(dictDef);
  const {classes} = useStyles();
  const {transitionLoginScreen, email} = props;
  const [inputValue, setInputValue] = useState<string>(email);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [running, setRunning] = useState<boolean>(false);
  const onChangeInputValue = useCallback(
    event => {
      setInputValue(event.target.value);
    },
    [setInputValue]
  );
  const loginWithExternalId = useCallback(async () => {
    setRunning(true);
    try {
      const flowResult = await (async () => {
        const isEmail = emailSchema.safeParse(inputValue).success;
        // redirectUriはPOSTでパラメータ渡すときにエンコードすると二重エンコードになるので、ここではエンコードしない
        if (isEmail) {
          return await OidcSsoService.startLoginFlow({
            email: inputValue,
            redirectUri: import.meta.env.VITE_SSO_REDIRECT_URL,
          });
        } else {
          return await OidcSsoService.startLoginFlowWithOrgCode({
            code: inputValue,
            redirectUri: import.meta.env.VITE_SSO_REDIRECT_URL,
          });
        }
      })();
      if (!flowResult) {
        throw new Error('not found flowResult');
      }

      addCookieValueByKey({
        key: SsoKeys.SsoIdProvider,
        value: flowResult.ssoIdProvider,
      });
      addCookieValueByKey({
        key: SsoKeys.Operation,
        value: SsoOperations.Signin,
      });

      // NOTE: 2024年3月現在、web側の外部IDログインはhhCrossのみ対応している。そのため、他のIDプロバイダーの場合はエラーを返す
      // TODO: 他のIDプロバイダーを追加する場合は、リダイレクト処理を分岐させるようにする。
      if (flowResult.ssoIdProvider !== SsoIdProviders.HhCross) {
        throw new Error('ssoIdProvider id not hhCross');
      }
      const {loginUrl} = flowResult;
      if (!loginUrl) {
        throw new Error('No sso login url');
      }

      window.location.href = loginUrl;
    } catch {
      setErrorMessage(dict.errorMessage);
    } finally {
      setRunning(false);
    }
  }, [dict.errorMessage, inputValue]);

  return (
    <LoginFormArea>
      {running && <WLoadingComponent message={''} />}
      <TitleArea>
        <WorkhubLogoIcon size={120} />
      </TitleArea>
      <Heading>{dict.descriptionText}</Heading>
      <InputArea>
        <InputWrapper>
          <BaseTextFiled
            id={'emailOrOrgCode'}
            label={dict.emailOrOrgCode}
            value={inputValue}
            onChange={onChangeInputValue}
            fullWidth
            variant='standard'
          />
        </InputWrapper>
        <ButtonArea>
          <ButtonWrapper>
            <WButton
              label={dict.loginWithExternalId}
              variant={'contained'}
              color={'secondary'}
              onClick={loginWithExternalId}
              disabled={!inputValue || running}
              width={'100%'}
              height={40}
              borderRadius={4}
              fontSize={14}
            />
          </ButtonWrapper>
          <ButtonWrapper>
            <Button
              className={classes.cancelButton}
              variant={'contained'}
              fullWidth
              onClick={transitionLoginScreen}
              disabled={running}
              disableElevation={true}
            >
              {dict.cancel}
            </Button>
          </ButtonWrapper>
        </ButtonArea>
        {errorMessage && <ErrorMessageArea>{dict.errorMessage}</ErrorMessageArea>}
      </InputArea>
    </LoginFormArea>
  );
});

export default LoginWithExternalIdScreen;
