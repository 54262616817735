export const SsoIdProviders = {
  ShoppersCloud: 'shoppersCloud',
  HhCross: 'hhCross',
} as const;

export const SsoOperations = {
  Signin: 'signin',
  Signup: 'signup',
} as const;

export const SsoKeys = {
  SsoIdProvider: 'ssoIdProvider',
  State: 'state',
  Operation: 'operation',
  InvitationId: 'invitationId',
  Code: 'code',
} as const;
