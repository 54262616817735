import {AccessControlVersionSettings} from '@bitkey-service/v2_core-types/lib/store/organizations/v2_storeTypesOrganization';
import {
  AuthorityLevel,
  FeatureAuthority,
} from '@bitkey-service/workhub-types/lib/firestore/organizations/authority-pattern/firestoreTypesOrganizationsAuthorityPattern';

import {Route} from '@/wscreens/routing/routing';

import {ActivationFeatureGroupMap, ActivationGroup, Feature, FeatureGroup} from '../featureDefinitions';

const ONLY_ACV1_MENU_KEY = ['faceRecognitionStatus', 'keyAndTickets'];

export class FeatureControlHelper {
  // これがないとsuperUserと権限未設定の一般ユーザーの区別がつかないため
  private readonly isSuperUser: boolean;

  private constructor(isSuperUser: boolean) {
    this.isSuperUser = isSuperUser;
  }

  public static createInstance = (isSuperUser: boolean) => new FeatureControlHelper(isSuperUser);
  public getActivatedFeatureGroups = (activationGroups: ActivationGroup[]) => {
    // 開発モードは絞らない
    if (import.meta.env.VITE_MODE === 'dev') {
      return Object.entries(ActivationFeatureGroupMap).map(([k]) => k);
    }
    return Object.entries(ActivationFeatureGroupMap)
      .filter(([, v]) => {
        for (const group of activationGroups) {
          if (v.includes(group)) {
            return true;
          }
        }
        return false;
      })
      .map(([k]) => k);
  };

  public filteredChildrenFeature = (
    children: Extract<Route, {type: 'withChildren'}>['children'],
    featureGroups: FeatureGroup[],
    features: Feature[],
    tempAccessControlVersionSettings: AccessControlVersionSettings | undefined
  ) => {
    return children.filter(routeChildren => {
      if (
        !(
          featureGroups.includes(routeChildren.featureGroup) &&
          routeChildren.feature &&
          features.includes(routeChildren.feature)
        )
      ) {
        return false;
      }

      if (!ONLY_ACV1_MENU_KEY.includes(routeChildren.dictKey)) {
        return true;
      }

      return !tempAccessControlVersionSettings?.useV2;
    });
  };

  public getAuthorizedFeatures = (featureAuthority: FeatureAuthority) => {
    // 開発モードは絞らない
    const allFeatures = Object.values(Feature);
    if (import.meta.env.VITE_MODE === 'dev') {
      return allFeatures;
    }
    // superUserは全部出す
    if (this.isSuperUser) {
      return allFeatures;
    }
    // Noneを含む設定がゼロの場合は権限未設定と見なして何も出さない
    if (Object.keys(featureAuthority).length === 0) {
      return [];
    }
    return allFeatures.filter(featureKey => {
      // 存在すらしないものは開放する（かどうかを設定で持つようにしたほうがいい。workhubが成長してから導入する場合と今で全然違うし、新機能追加時にセキュリティを重視するか見てみるほうを重視するかとか）
      const authorizedFeature = featureAuthority[featureKey as string];
      // TODO Read権限対応
      return authorizedFeature && authorizedFeature === AuthorityLevel.Write;
    });
  };
}
