import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import MuiTextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, {useCallback, useState} from 'react';
import {makeStyles, withStyles} from 'tss-react/mui';

import useDict, {useCommonDict} from '@/common/hooks/useDict';
import {shadowColor, WHColor} from '@/common/styles/whColor';

import {Locale} from '../../common/redux/state-types/localeStateType';
import WLoadingComponent from '../../components/figma/others/stepper/WLoadingComponent';
import WorkhubMinLogoIcon from '../../components/icon/WorkhubMinLogoIcon';
import SimpleSnackbar, {SimpleSnackbarState, SimpleSnackbarType} from '../../components/snackbar/SimpleSnackbar';
import {AuthService} from '../../services/auth/authService';

interface P {
  transitionLoginScreen: () => void;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
}

const LoginFormArea = styled.div`
  width: 385px;
  height: 680px;
  padding: 16px;
  box-sizing: border-box;
  box-shadow: 0 4px 10px ${shadowColor.shadowAlpha020};
  border-radius: 8px;
  background-color: ${WHColor.surface.neutralLow};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

// デザインどうなるかわからんからとりあえずworkhubってかいとく
const TitleArea = styled.div`
  height: 50px;
  width: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const DescriptionArea = styled.div`
  margin-top: 50px;
  height: 94px;
  width: 227px;
  text-align: center;
`;

const InputArea = styled.div`
  height: 48px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
`;

const ErrorMessageArea = styled.div`
  color: ${WHColor.text.semanticError};
  margin-top: 16px;
`;

const InputWrapper = styled.div`
  height: 48px;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ButtonArea = styled.div`
  display: flex;
  margin-top: 86px;
  width: 80%;
`;

const useStyles = makeStyles()(() => ({
  cancelButton: {
    marginRight: 4,
    borderRadius: 4,
    fontSize: '14px',
  },
  sendButton: {
    marginLeft: 4,
    borderRadius: 4,
    fontSize: '14px',
  },
  descriptionTitle: {
    letterSpacing: 'inherit',
    fontSize: '23px',
  },
  descriptionText: {
    marginTop: 15,
    letterSpacing: 1.5,
    fontSize: 11,
    textAlign: 'center',
  },
}));

const BaseTextFiled = withStyles(MuiTextField, {
  root: {
    height: '61px',
    border: '2px',
    background: `${WHColor.surface.neutralLow}`,
    marginBottom: '2px',
    fontSize: '14px',
    '& .MuiInput-underline:before': {
      borderBottom: `2px solid ${WHColor.border.onLightDefault}`,
      color: `${WHColor.text.neutralSecondary}`,
      fontSize: '14px',
    },
    '& .MuiInputLabel-root': {
      opacity: 0.8,
    },
  },
});

const dictDef = {
  failedToSendPasswordResetEmail: {
    default: {
      default: 'メール送信に失敗しました',
      [Locale.en_US]: 'Failed to send Email',
    },
  },
  successToSendPasswordResetEmail: {
    default: {
      default: 'パスワードリセットメールを送信しました',
      [Locale.en_US]: 'Password reset Email is sent',
    },
  },
  descriptionTitle: {
    default: {
      default: 'パスワードリセット',
      [Locale.en_US]: 'Password Reset',
    },
  },
  descriptionText: {
    default: {
      default: 'パスワードをリセットするためのURLを発行します。送信先のメールアドレスを入力してください。',
      [Locale.en_US]: 'Please input your email below. A link to reset your password will be sent.',
    },
  },
};

const ResetPasswordScreen = React.memo(function ResetPasswordScreen(props: P) {
  const dict = useDict(dictDef);
  const commonDict = useCommonDict();
  const {classes} = useStyles();
  const {transitionLoginScreen, email, setEmail} = props;
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [running, setRunning] = useState<boolean>(false);
  const [snackbarState, setSnackbarState] = useState<SimpleSnackbarState>({
    open: false,
    message: '',
    type: SimpleSnackbarType.Success,
  });
  const onChangeEmail = useCallback(
    event => {
      setEmail(event.target.value);
    },
    [setEmail]
  );
  const sendEmail = useCallback(async () => {
    setRunning(true);
    try {
      await AuthService.issueBulkPasswordResetToken(email, 'workhub');
      setSnackbarState({
        open: true,
        message: dict.successToSendPasswordResetEmail,
        type: SimpleSnackbarType.Success,
      });
    } catch {
      setErrorMessage(dict.failedToSendPasswordResetEmail);
    } finally {
      setRunning(false);
    }
  }, [dict.failedToSendPasswordResetEmail, dict.successToSendPasswordResetEmail, email]);

  return (
    <LoginFormArea>
      {running && <WLoadingComponent message={''} />}
      <TitleArea>
        <WorkhubMinLogoIcon size={51} />
      </TitleArea>
      <DescriptionArea>
        <Typography className={classes.descriptionTitle}>{dict.descriptionTitle}</Typography>
        <Typography className={classes.descriptionText}>{dict.descriptionText}</Typography>
      </DescriptionArea>
      <InputArea>
        <InputWrapper>
          <BaseTextFiled
            id={'email'}
            label={commonDict.emailAddress}
            type={'email'}
            value={email}
            onChange={onChangeEmail}
            fullWidth
            variant='standard'
          />
        </InputWrapper>
        <ButtonArea>
          <Button
            className={classes.cancelButton}
            color={'secondary'}
            variant={'contained'}
            fullWidth
            onClick={transitionLoginScreen}
            disabled={running}
            disableElevation={true}
          >
            {commonDict.cancel}
          </Button>
          <Button
            className={classes.sendButton}
            color={'primary'}
            variant={'contained'}
            fullWidth
            onClick={sendEmail}
            disabled={!email || running}
            disableElevation={true}
          >
            {commonDict.send}
          </Button>
        </ButtonArea>
        {errorMessage && <ErrorMessageArea>{errorMessage}</ErrorMessageArea>}
        <SimpleSnackbar snackbarState={snackbarState} setSnackbarState={setSnackbarState} />
      </InputArea>
    </LoginFormArea>
  );
});

export default ResetPasswordScreen;
