import {OrganizationBusinessType} from '@bitkey-service/v2_core-types/lib/store/organizations/v2_storeTypesOrganization';
import {TimestampToEpochMillis} from '@bitkey-service/workhub-functions-common-types/lib/common/typeUtils';
import {
  ApiTypesAuthPostResponse,
  ApiTypesAuthPostTokenResponse,
} from '@bitkey-service/workhub-types/lib/api/workhub-account-api-types/auth/apiTypesAuth';
import {
  ActivationGroup,
  FirestoreTypesOrganizationsActivations,
} from '@bitkey-service/workhub-types/lib/firestore/organizations/activations/firestoreTypesOrganizationsActivations';
import {
  AuthorityLevel,
  FeatureAuthority,
  FirestoreTypesOrganizationsAuthorityPattern,
} from '@bitkey-service/workhub-types/lib/firestore/organizations/authority-pattern/firestoreTypesOrganizationsAuthorityPattern';
import {FirestoreTypesOrganizationsRole} from '@bitkey-service/workhub-types/lib/firestore/organizations/roles/firestoreTypesOrganizationsRoles';
import {Dispatch} from '@reduxjs/toolkit';

import {ApiAccountAuth, MailTemplate} from '../../api/account/auth/apiAccountAuth';
import {CommonError} from '../../common/error/commonError';
import {Feature} from '../../common/feature-control/featureDefinitions';
import {FirebaseFunctions} from '../../common/firebase/functions/firebase-functions';
import Logger from '../../common/logger/logger';
import rolesSlice from '../../common/redux/slices/rolesSlice';
import {LoginPersonaLocalStorage} from '../../common/storage/localStorage';

const logger = Logger.create('authService');

export class AuthService {
  private constructor() {}
  private static instance: AuthService;
  public static getInstance = () => {
    if (!AuthService.instance) {
      AuthService.instance = new AuthService();
    }
    return AuthService.instance;
  };

  public refreshAccessToken = async () => {
    const {refreshToken} = LoginPersonaLocalStorage.get();
    if (!refreshToken) {
      return;
    }
    try {
      const response = await ApiAccountAuth.refreshAccessToken({refreshToken: refreshToken});
      LoginPersonaLocalStorage.setOnRefresh({
        accessToken: response.accessToken,
        refreshToken: response.refreshToken,
      });
      FirebaseFunctions.setAccessToken(response.accessToken);
    } catch (e) {
      logger.error('failed to refresh token.', e);
    }
  };

  public createAuthorityForMobile = async ({
    authorityPatterns,
    roles,
    authorityRoleIds,
    userGroupRights,
    activations,
    v2SuperUser,
  }: {
    authorityPatterns: TimestampToEpochMillis<FirestoreTypesOrganizationsAuthorityPattern[]>;
    roles: TimestampToEpochMillis<FirestoreTypesOrganizationsRole[]>;
    authorityRoleIds?: string[];
    userGroupRights: {id: string; userGroupId: string; authorityPatternIds: string[]}[];
    activations: TimestampToEpochMillis<FirestoreTypesOrganizationsActivations[]>;
    v2SuperUser: boolean;
  }) => {
    // ネーミングがよくないが、V2組織かどうかを判定している
    const isV2Organization = Object.values(activations).some(
      // ActivationGroupがクライアントとバックエンド側両方で定義されているが故のこの実装
      // ActivationGroupが統合されてたらここも修正
      value => value.activationGroup === ('memberManagement' as ActivationGroup)
    );

    if (isV2Organization && v2SuperUser) {
      return {};
    }

    const authority: FeatureAuthority = {};
    const levels: AuthorityLevel[] = [AuthorityLevel.None, AuthorityLevel.Read, AuthorityLevel.Write];
    if (authorityPatterns) {
      const patternIds: string[] = [];
      if (authorityRoleIds) {
        for (const roleId of authorityRoleIds) {
          const role = roles.find(r => r.id === roleId);
          if (role) {
            patternIds.push(...role.authorityIds);
          }
        }
      }
      if (userGroupRights?.length) {
        for (const userGroupRight of userGroupRights) {
          patternIds.push(...userGroupRight.authorityPatternIds);
        }
      }
      const authorityPatternIds = Array.from(new Set(patternIds));
      const patterns = authorityPatterns.filter(p => authorityPatternIds.includes(p.id));
      for (const authorityPattern of patterns) {
        for (const feature of Object.keys(authorityPattern.authority)) {
          if (authority[feature]) {
            if (
              levels.findIndex(l => l === authority[feature]) <
              levels.findIndex(l => l === authorityPattern.authority[feature])
            ) {
              authority[feature] = authorityPattern.authority[feature];
            }
          } else {
            authority[feature] = authorityPattern.authority[feature];
          }
        }
      }

      // authorityはそのまま持たせたいが、bitlockAppとbitReceptionの権限は管理画面と無関係なので除外する
      const ignoreFeatures: Feature[] = [
        Feature.V2BitlockApp,
        Feature.V2ReceptionApp,
        Feature.V2WorkhubAppOrganization,
        Feature.WorkhubAppPersonalizedNfcCard,
      ];
      const levelsForAuthorityCheck = Object.keys(authority).map(key =>
        ignoreFeatures.includes(key as Feature) ? AuthorityLevel.None : authority[key as Feature]
      );

      // からっぽは権限未設定を考慮してログインできないといけないけど全部Noneなのはログインできないようにしていると考えられるためログイン画面に戻す
      if (levelsForAuthorityCheck.length > 0 && levelsForAuthorityCheck.every(l => l === AuthorityLevel.None)) {
        throw new CommonError('no-authority-available');
      }
    }
    return authority;
  };

  /**
   * ログインとAuth時（再読み込み時）の共通処理
   *
   * @param {({
   *       businessType: OrganizationBusinessType;
   *       loginPersona: ApiTypesAuthPostResponse | ApiTypesAuthPostTokenResponse;
   *     })} data
   * @param {Logger} logger
   * @param {Dispatch<any>} dispatch
   * @memberof AuthService
   */
  public authSettingCommon = (
    data: {
      businessType: OrganizationBusinessType;
      loginPersona: ApiTypesAuthPostResponse | ApiTypesAuthPostTokenResponse;
    },
    logger: Logger,
    dispatch: Dispatch<any>
  ) => {
    const {businessType, loginPersona} = data;
    const {roles} = loginPersona;
    if (
      businessType === OrganizationBusinessType.CORE ||
      businessType === OrganizationBusinessType.RESIDENCE ||
      businessType === OrganizationBusinessType.CO_WORKING
    ) {
      logger.warn('This organization is for bSK, not workhub');
      throw new Error('This organization is for bSK, not workhub');
    }

    dispatch(
      rolesSlice.actions.set({
        roles,
        loaded: true,
      })
    );
  };

  /**
   * パスワード再発行のためのtoken発行処理
   * @param email
   * @param mailTemplate
   */
  public static issueBulkPasswordResetToken: typeof ApiAccountAuth.issueBulkPasswordResetToken = async (
    email,
    mailTemplate
  ) => {
    await ApiAccountAuth.issueBulkPasswordResetToken(email, mailTemplate);
  };

  /**
   * トークンを利用してパスワードを一括でリセットする
   * 操作者のメールアドレスに紐づくペルソナ全てのパスワードをリセットする
   * @param password
   * @param bulkResetToken
   * @param mailTemplate
   */
  public static bulkResetPassword = async (password: string, bulkResetToken: string, mailTemplate: MailTemplate) => {
    await ApiAccountAuth.bulkResetPassword(password, bulkResetToken, mailTemplate);
  };
}
