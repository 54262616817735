import {ActivationGroup} from '@bitkey-service/v2_core-types/lib/store/organizations/activations/v2_storeTypesOrgActivations';
import styled from '@emotion/styled';
import React, {useCallback, useMemo, useState} from 'react';

import {useLoginUser} from '@/common/hooks/useLoginUser';
import {useSnackbar} from '@/common/hooks/useSnackbar';
import Logger from '@/common/logger/logger';
import {WCheckbox} from '@/components/checkbox/WCheckbox';
import WDialog from '@/components/dialog/WDialog';

import {DebuggerService} from '../debuggerService';

const logger = Logger.create('ActivationsDeleteDialog');
const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ActivationsDeleteDialog = React.memo<{
  open: boolean;
  onClose: () => any;
  disabled?: boolean;
}>(function ActivationsDeleteDialog(props) {
  const {open, onClose, disabled} = props;

  const [running, setRunning] = useState<boolean>(false);
  const snackbar = useSnackbar();
  const user = useLoginUser();

  const activationsList = useMemo(
    () => user.activations.map(a => a.activationGroup).sort((a, b) => a.localeCompare(b)),
    [user.activations]
  );

  const [activationsCheckList, setActivationsCheckList] = useState<{[key in ActivationGroup]?: boolean}>(
    activationsList.reduce((prev, current) => {
      prev[current] = false;
      return prev;
    }, {})
  );

  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const activation = event.target.value as ActivationGroup;
    setActivationsCheckList(checkList => {
      checkList[activation] = checked;
      return checkList;
    });
  }, []);

  const checkActivations = useMemo(() => {
    return activationsList.map((activation, idx) => {
      return (
        <Wrapper key={idx}>
          <WCheckbox onChange={onChange} value={activation} />
          {activation}
        </Wrapper>
      );
    });
  }, [activationsList, onChange]);

  const onOk = useCallback(async () => {
    setRunning(true);

    try {
      const activationGroups = activationsList.filter(v => activationsCheckList[v]);
      await DebuggerService.deleteActivations(activationGroups);
      DebuggerService.pageReload();
      snackbar.success('表示メニューの削除が完了しました');
    } catch (e) {
      logger.error('failed to activation delete.', e);
      snackbar.fail('表示メニューの削除に失敗しました');
    } finally {
      setRunning(false);
      onClose();
    }
  }, [activationsCheckList, activationsList, onClose, snackbar]);

  return (
    // 削除なら本来buttonの色赤くするとかするべきなんだけど、現状WDialogはbuttonの色変更に対応していない
    // それに誤って削除したところで、再度更新かければ良いだけなのでとりあえずこれで
    <WDialog
      open={open}
      onClose={onClose}
      running={running}
      title={'メニュー表示(activation)の削除'}
      disabled={disabled}
      onOk={onOk}
      okButtonLabel={'削除'}
    >
      {checkActivations}
    </WDialog>
  );
});

export default ActivationsDeleteDialog;
